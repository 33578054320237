<template>
    <div class="col-12">
        <swiper class="swiper" :options="swiperOption" ref="swiper">
            <swiper-slide class="d-flex" v-for="(review, index) in googleReviews" v-bind:key="index">
                <Review :review="review" />
            </swiper-slide>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>
<script>

    import Review from "@/components/Review.vue";
    import SwiperClass, { Navigation, Pagination } from "swiper";
    // configure Swiper to use modules
    SwiperClass.use([Navigation, Pagination]);

    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/swiper-bundle.css'

export default {
    name: 'GoogleReviews',
    components: {
        Review,
        Swiper,
        SwiperSlide
    },
    props: ['slidesPerView'],
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOption: {
              slidesPerView: this.slidesPerView,
              centeredSlides: false,
              spaceBetween: 50,
                observer:true,
              pagination: {
                el: '.swiper-pagination',
                clickable: true
              },
              navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
              }
            },
            renderSlides: false,
        }
    },
    mounted() {
    },
    methods: {
    },
    computed: {
      googleReviews() { return this.$store.state.googleReviews },

    }
}
</script>
<style lang="scss" scoped>

    .swiper-container{
        padding-bottom: 35px;
        padding-right:50px;
        padding-left:50px;

        &> .swiper-pagination {
           position: absolute;
           bottom: 0;
       }

       .swiper-button-next{
           position: absolute;
           right: 0;

       }

       .swiper-button-prev{
           position: absolute;
           left: 0;

       }
    }
</styles>