<template>
        <div class="media">
          <img :src="review.author_photo" class="mr-4" :alt="review.author + ' Proilfoto'">
          <div class="media-body">
            <div  @click.prevent="openReview(review.author_url)">
            <h5 class="mt-0">{{review.author}}</h5>
            <p>
                <span v-for="i in review.rating" :key="'star-'+i">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#E6BB03" class="bi bi-star-fill" :class="'star-'+i" viewBox="0 0 16 16">
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                </span>

                <span v-for="k in (5 - review.rating)" :key="'no-star-'+k">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="#E6BB03" class="bi bi-star"  :class="'no-star-'+k" viewBox="0 0 16 16">
                      <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z"/>
                    </svg>
                </span>
            </p>
            <p>{{ review.relative_time}}</p>
            <p :class="reviewText">{{ review.text}}</p>
          </div>
          <a v-if="review.text" class="show-more" @click.prevent="showMore">Mehr</a>
          </div>
        </div>
</template>
<script>

export default {
    name: 'Review',
    components: {
    },
    props: ['review'],
    data() {
        return {
            reviewText: 'review-text',
        }
    },
    methods: {
        openReview(url){
            window.open(url, "_blank");
        },
        showMore(){
            this.reviewText = "";
        }
    },
}
</script>
<style lang="scss" scoped>

    .media{

        cursor: pointer;

        .review-text{
            height: 4.5em;
            overflow: hidden;
        }

        img{
            width:14%;
        }
        h5{
            a{
                color: #000;
                font-size: 23px;
                letter-spacing: 0.57px;
                line-height: 28px;
            }
        }

        svg{
            width:1.5em;
            height:1.5em;
        }
    }
</styles>